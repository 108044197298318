<template>
    <div class="contain">
        <div class="contain-login">
            <div class="login-title">全民柔力球比赛</div>
            <div class="login-form">
                <div class="form-title">评委编号</div>
                <div class="form-input">
                    <el-input v-model="judgeCode" clearable :disabled="openLogin" placeholder="请输入评委编号"></el-input>
                </div>
            </div>
            <div class="login-btn">
                <div class="btn-one" @click="getInIndex">登 录</div>
                <!--				<div class="btn-one" style="background-color: #4d657b" @click="getInAdmin">前往管理员登录</div>-->
            </div>
        </div>
    </div>
</template>

<script>
    import {judgeLogin} from "../api/adminLogin";

    export default {
        data() {
            return {
                openLogin: false,
                linkCode: null,
                judgeCode: null
            }
        },
        methods: {
            getInAdmin() {
                this.$router.replace('/')
            },
            async getInIndex() {
                if (this.openLogin === true) {
                    this.$message.warning('请进入正确的链接入口');
                    return false
                }
                if (!this.judgeCode) {
                    this.$message.warning('请输入登陆的评委编号');
                    return false
                }
                //执行登陆方法
                let obj = {
                    linkCode: this.linkCode,
                    judgeCode: this.judgeCode
                }
                const {data} = await judgeLogin(obj)
                if (data.code === 200) {
                    window.sessionStorage.setItem('token', data.data.tokenInfo.tokenValue)
                    //保存比赛信息
                    window.sessionStorage.setItem('event',JSON.stringify(data.data.event))
                    window.sessionStorage.setItem('userInfo', JSON.stringify(data.data.judge))
                    window.sessionStorage.setItem('roleFlag',"2");
                    await this.$router.push('/index')
                }
            }
        },
        mounted() {
            sessionStorage.removeItem("linkCode");
            //获取链接标识，判断是哪个比赛
            this.linkCode = this.$route.query.linkCode;
            if (this.linkCode) {
                this.openLogin = false;
                sessionStorage.setItem("linkCode", this.linkCode);
            } else {
                this.openLogin = true;
            }
        }
    }
</script>

<style scoped lang="less">
    .contain {
        position: relative;
        margin: 0px;
        width: 100%;
        height: 100%;
        background: url("../assets/images/judgesBanner.jpg") no-repeat;
        background-size: 100% 100%;
        background-attachment: fixed;

        .contain-login {
            position: absolute;
            right: 10%;
            top: 50%;
            transform: translateY(-50%);
            width: 500px;
            height: 500px;
            display: flex;
            padding: 20px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: rgba(255, 255, 255, 0.8);
            border-radius: 10px;

            .login-title {
                width: 100%;
                font-size: 50px;
                line-height: 50px;
                text-align: center;
                color: #4d657b;
                font-weight: bold;
            }

            .login-form {
                width: 100%;
                margin: 50px 0;
                padding: 0 50px;
                box-sizing: border-box;

                .form-title {
                    width: 100%;
                    text-align: center;
                    color: #4d657b;
                    font-weight: bold;
                    font-size: 30px;
                }

                .form-input {
                    width: 100%;
                    height: 50px;
                    margin: 20px 0;
                    padding: 0 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-sizing: border-box;
                    background-color: #fff;
                    border-radius: 25px;

                    /deep/ .el-input__inner {
                        width: 100%;
                        height: 100%;
                        border: none;
                    }
                }
            }

            .login-btn {
                width: 100%;
                padding: 0 50px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-around;

                .btn-one {
                    cursor: pointer;
                    width: 100%;
                    height: 40px;
                    background-color: #f19b5d;
                    border-radius: 20px;
                    text-align: center;
                    line-height: 40px;
                    color: #fff;
                    font-weight: bold;
                }
            }
        }
    }
</style>
