import { render, staticRenderFns } from "./judgesLogin.vue?vue&type=template&id=39172c71&scoped=true&"
import script from "./judgesLogin.vue?vue&type=script&lang=js&"
export * from "./judgesLogin.vue?vue&type=script&lang=js&"
import style0 from "./judgesLogin.vue?vue&type=style&index=0&id=39172c71&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39172c71",
  null
  
)

export default component.exports